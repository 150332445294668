
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Demo1',
    setup() {
        return {
            
        }
    },
})
